<template>
  <div class="card-shadow border-radius-xl v-card v-sheet theme--light">
    <br />
    <h1 class="ml-1">App Settings</h1>
    <form class="">
      <div class="ml-2">
        <label for="inputPositionstack">Positionstack API key</label>
      </div>
      <v-text-field
        v-model="positionstackKey"
        outlined
        class="ml-2 mr-2"
        id="inputPositionstack"
        placeholder="Enter API key"
      >
      </v-text-field>
      <div class="ml-2 mr-2">
        <label for="inputEmail1">Email address</label>
      </div>
      <v-text-field
        v-model="email"
        class="ml-2 mr-2"
        outlined
        id="inputEmail1"
        aria-describedby="emailHelp"
        placeholder="Enter email"
      >
      </v-text-field>
      <div class="ml-2 mr-2">
        <label for="backfillDays">Backfill days</label>
      </div>
      <v-text-field
        v-model="backfillDays"
        class="ml-2 mr-2"
        outlined
        id="backfillDays"
        placeholder="Enter days"
      >
      </v-text-field>
      <div class="ml-2 mr-2">
        <label for="solaredgeAcctKey">SolarEdge Account key</label>
      </div>
      <v-text-field
        v-model="solaredgeAcctKey"
        outlined
        class="ml-2 mr-2"
        id="solaredgeAcctKey"
        placeholder="Enter key"
      >
      </v-text-field>
      <div class="ml-2 mr-2">
        <label for="froniusAcctKeypair">Fronius Account key</label>
      </div>
      <v-text-field
        v-model="froniusAcctKeypair"
        outlined
        class="ml-2 mr-2"
        id="froniusAcctKeypair"
        placeholder="Enter key"
      ></v-text-field>
      <div class="ml-2 mr-2">
        <label for="pvwattsApiKey">PV Watts key</label>
      </div>
      <v-text-field
        v-model="pvwattsApiKey"
        outlined
        class="ml-2 mr-2"
        id="pvwattsApiKey"
        placeholder="Enter key"
      ></v-text-field>
      <v-btn
        :ripple="false"
        :elevation="0"
        class="font-weight-bold text-xs btn-default bg-gradient-default ml-2 mb-2"
        @click="save"
      >
        Save
      </v-btn>
    </form>
  </div>
</template>

<script>
import Service from "@/services/Service.js";
import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
// import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);

export default {
  data() {
    return {
      email: null,
      positionstackKey: null,
      solaredgeAcctKey: null,
      isEditing: false,
      froniusAcctKeypair: null,
      pvwattsApiKey: null,
      backfillDays: null,
    };
  },
  created() {
    // console.log("loaded: " + this.site.name);
    this.loadSettings();
  },
  mounted() {
    document.title = "Admin"
  },
  methods: {
    loadSettings() {
      Service.getSettings()
        .then((response) => {
          console.log(response.data);
          this.email = this.parseVal(response.data, "admin_email");
          this.positionstackKey = this.parseVal(
            response.data,
            "positionstack_key"
          );
          this.solaredgeAcctKey = this.parseVal(
            response.data,
            "solaredge_acct_key"
          );
          this.froniusAcctKeypair = this.parseVal(
            response.data,
            "fronius_acct_key"
          );
          this.pvwattsApiKey = this.parseVal(response.data, "pvwatts_api_key");
          this.backfillDays = this.parseVal(response.data, "backfill_days");

          // Vue.$toast.success(`Data reload queued for site ${item.name}`, {position: "top-right", duration: 4000})
        })
        .catch((error) => {
          // Vue.$toast.error(`Error reloading data`, {position: "top-right"})
          console.error(error);
        });
    },
    parseVal(settings, key) {
      let obj = settings.find((s) => s.key == key);
      if (obj) {
        return obj.value;
      } else {
        return "";
      }
    },
    save() {
      var params = {
        settings: [
          { admin_email: this.email },
          { positionstack_key: this.positionstackKey },
          { solaredge_acct_key: this.solaredgeAcctKey },
          { fronius_acct_key: this.froniusAcctKeypair },
          { pvwatts_api_key: this.pvwattsApiKey },
          { backfill_days: this.backfillDays },
        ],
      };
      Service.saveSettings(params)
        .then((response) => {
          Vue.$toast.success(`Settings saved.`, {
            position: "top-right",
            duration: 4000,
          });
        })
        .catch((error) => {
          Vue.$toast.error(`Error saving settings`, { position: "top-right" });
          console.error(error);
        });
    },
  },
  computed: {},
};
</script>

<style>
</style>