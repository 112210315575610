var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-shadow border-radius-xl v-card v-sheet theme--light" },
    [
      _c("br"),
      _c("h1", { staticClass: "ml-1" }, [_vm._v("App Settings")]),
      _c(
        "form",
        {},
        [
          _vm._m(0),
          _c("v-text-field", {
            staticClass: "ml-2 mr-2",
            attrs: {
              outlined: "",
              id: "inputPositionstack",
              placeholder: "Enter API key",
            },
            model: {
              value: _vm.positionstackKey,
              callback: function ($$v) {
                _vm.positionstackKey = $$v
              },
              expression: "positionstackKey",
            },
          }),
          _vm._m(1),
          _c("v-text-field", {
            staticClass: "ml-2 mr-2",
            attrs: {
              outlined: "",
              id: "inputEmail1",
              "aria-describedby": "emailHelp",
              placeholder: "Enter email",
            },
            model: {
              value: _vm.email,
              callback: function ($$v) {
                _vm.email = $$v
              },
              expression: "email",
            },
          }),
          _vm._m(2),
          _c("v-text-field", {
            staticClass: "ml-2 mr-2",
            attrs: {
              outlined: "",
              id: "backfillDays",
              placeholder: "Enter days",
            },
            model: {
              value: _vm.backfillDays,
              callback: function ($$v) {
                _vm.backfillDays = $$v
              },
              expression: "backfillDays",
            },
          }),
          _vm._m(3),
          _c("v-text-field", {
            staticClass: "ml-2 mr-2",
            attrs: {
              outlined: "",
              id: "solaredgeAcctKey",
              placeholder: "Enter key",
            },
            model: {
              value: _vm.solaredgeAcctKey,
              callback: function ($$v) {
                _vm.solaredgeAcctKey = $$v
              },
              expression: "solaredgeAcctKey",
            },
          }),
          _vm._m(4),
          _c("v-text-field", {
            staticClass: "ml-2 mr-2",
            attrs: {
              outlined: "",
              id: "froniusAcctKeypair",
              placeholder: "Enter key",
            },
            model: {
              value: _vm.froniusAcctKeypair,
              callback: function ($$v) {
                _vm.froniusAcctKeypair = $$v
              },
              expression: "froniusAcctKeypair",
            },
          }),
          _vm._m(5),
          _c("v-text-field", {
            staticClass: "ml-2 mr-2",
            attrs: {
              outlined: "",
              id: "pvwattsApiKey",
              placeholder: "Enter key",
            },
            model: {
              value: _vm.pvwattsApiKey,
              callback: function ($$v) {
                _vm.pvwattsApiKey = $$v
              },
              expression: "pvwattsApiKey",
            },
          }),
          _c(
            "v-btn",
            {
              staticClass:
                "font-weight-bold text-xs btn-default bg-gradient-default ml-2 mb-2",
              attrs: { ripple: false, elevation: 0 },
              on: { click: _vm.save },
            },
            [_vm._v(" Save ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-2" }, [
      _c("label", { attrs: { for: "inputPositionstack" } }, [
        _vm._v("Positionstack API key"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-2 mr-2" }, [
      _c("label", { attrs: { for: "inputEmail1" } }, [_vm._v("Email address")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-2 mr-2" }, [
      _c("label", { attrs: { for: "backfillDays" } }, [
        _vm._v("Backfill days"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-2 mr-2" }, [
      _c("label", { attrs: { for: "solaredgeAcctKey" } }, [
        _vm._v("SolarEdge Account key"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-2 mr-2" }, [
      _c("label", { attrs: { for: "froniusAcctKeypair" } }, [
        _vm._v("Fronius Account key"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-2 mr-2" }, [
      _c("label", { attrs: { for: "pvwattsApiKey" } }, [
        _vm._v("PV Watts key"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }